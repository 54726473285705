<template>
  <div class="imageUpload">
    <el-upload
      class="avatar-uploader"
      :action="action"
      :headers="headers"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :on-error="errorUpload"
      :before-upload="beforeUpload"
      :disabled="uploadDisabled"
    >
      <div v-if="imgUrl" class="avatar">
        <img :src="$fileUrl + imgUrl">
        <div class="delete-shadow" @click.stop="upload = !upload">
          <p v-show="download" class="download-btn" @click.stop="downloadPicture">
            <i class="download" />下载
          </p>
          <p @click.stop="previewPicture">
            <i class="view" />查看
          </p>
          <p v-show="!uploadDisabled" @click.stop="removePicture">
            <i class="delete" />删除
          </p>
        </div>
      </div>
      <div v-else class="uploader-bitmap">
        <div class="avatar-uploader-icon">
          <i class="el-icon-plus" />
          <span>{{ explain }}</span>
        </div>
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogImageVisible" :close-on-click-modal="false">
      <img :src="$fileUrl + imgUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import { baseURL } from '@/systemConfiguration/index'
import { downloadFile } from '@/utils/util.js'
export default {
  props: {
    editImgUrl: {
      default: '',
      type: String
    },
    editImgName: {
      default: '',
      type: String
    },
    // 限制只能是图片  如果为true则只能是图片  为false则什么都可以的
    canOnlyPictures: {
      default: true,
      type: Boolean
    },
    // 身份证正反面
    idCardFront: {
      default: null,
      type: String
    },
    explain: {
      default: '',
      type: String
    },
    // 是否可编辑
    uploadDisabled: {
      default: false,
      type: Boolean
    },
    download: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      headers: {
        token: ''
      },
      action: `${baseURL}pjht/bill/manage/getBillOcrInfo`,
      imgUrl: '',
      dialogImageVisible: false,
      upload: false
    }
  },
  watch: {
    editImgUrl: {
      handler(newVal) {
        this.imgUrl = newVal
      },
      immediate: true,
      deep: true
    },
    editImgName: {
      handler(newVal) {
        this.imgName = newVal
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    this.headers.token = localStorage.getItem('piaojuToken')
  },
  methods: {
    beforeUpload(file) {
      const arr = file.name.split('.')
      if (this.canOnlyPictures && !['jpg', 'png', 'psd', 'tif', 'bmp'].includes(arr[1])) {
        this.$message.error('上传的格式只能是jpg, png,  psd, tif, bmp')
        return false
      }
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isLt5M) {
        this.$message.warning('图片大小不能超过 5M')
        return false
      }
    },
    // 上传失败提示
    errorUpload() {
      this.$message.warning('文件上传失败！')
    },
    handleAvatarSuccess(res) {
      if (res.code === 200) {
        this.imgUrl = res.data.billFileUrl
        this.$emit('getImgUrl', this.imgUrl)
        this.$emit('getBillData', res.data)
        return
      }
      this.$message.warning('文件上传失败！')
    },
    // 预览图片
    previewPicture() {
      this.dialogImageVisible = true
    },
    removePicture() {
      this.imgUrl = ''
    },
    // 下载
    downloadPicture() {
      downloadFile(this.$fileUrl + this.imgUrl, this.imgName)
    }
  }
}
</script>

<style scoped lang="scss">
.avatar-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  // width: 228px;
  // height: 126px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

:deep(.el-upload) {
  display: block;
  height: 100%;
}

.avatar-uploader:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  color: #585858;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  i {
    // width: 39px;
    // height: 39px;
    // display: inline-block;
    // background: url('../assets/image/file_icon.png') no-repeat top center;
    // background-size: 100% 100%;
  }
  span {
    font-size: 16px;
    line-height: 16px;
    margin-top: 20px;
  }
}

.avatar-uploader .avatar {
  width: 100%;
  height: 100%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    display: block;
  }

  // &:hover {
    .delete-shadow {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 48px;
      background-color: rgba(0,0,0,.7);
      transition: 0.2s;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      p {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 12px;
      }
      i {
        margin-right: 4px;
      }
      .download-btn {
        position: relative;
         &::before {
          content: '';
          position: absolute;
          right: 0px;
          top: 12px;
          width: 1px;
          height: 24px;
          background-color: rgba(255,255,255,.7);
        }
      }
      .download {
        display: inline-block;
        width: 24px;
        height: 24px;
        background: url('../../../../assets/image/piaojuImg/icon_download.png') no-repeat top center;
        background-size: 100% 100%;
      }
      .view {
        display: inline-block;
        width: 24px;
        height: 24px;
        background: url('../../../../assets/image/piaojuImg/icon_view.png') no-repeat top center;
        background-size: 100% 100%;
      }
      .delete {
        display: inline-block;
        width: 24px;
        height: 24px;
        background: url('../../../../assets/image/piaojuImg/icon_delete.png') no-repeat top center;
        background-size: 100% 100%;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: -12px;
          top: 2px;
          width: 1px;
          height: 24px;
          background-color: rgba(255,255,255,.7);
        }
      }
    }
  // }

  // .delete {
  //   width: 16px;
  //   height: 16px;
  //   display: inline-block;
  //   background: url('../assets/image/delete.png') no-repeat top center;
  //   background-size: 100% 100%;
  //   position: absolute;
  //   z-index: 1;
  //   right: 0;
  //   top: 0
  // }
}

.uploader-bitmap {
  position: relative;
  height: 100%;
}
.id-card-img {
  width: 182px;
  height: 92px;
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}

:deep(.el-dialog) {
  min-width: 1054px;
  min-height: 605px;
  background-color: rgba(0,0,0,0);
  box-shadow: none;
  display: flex;
  flex-direction: column;
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
  .el-dialog__headerbtn {
    width: 30px;
    height: 30px;
    top: 50px;
    font-size: 20px;
    line-height: 30px;
  }
  .el-dialog__close {
    color: #fff;
  }
  img {
    max-width: 100%;
  }
}
:deep(.el-overlay) {
  background-color: rgba(0,0,0,.7)
}
</style>
